import ProductCard from '@/components/shared/product-card'
import { cn } from '@/lib/utils'
import { Product } from '@/types'

type FeatureProductsProps = {
  title?: string
  className?: string
  products?: Product[]
  primaryNavLayout: 'single' | 'double'
  isDisabled?: boolean
}

const FeatureProducts = ({
  title,
  products,
  className,
  primaryNavLayout,
  isDisabled,
}: FeatureProductsProps) => {
  const productsLength = products?.length
  if (!productsLength) return null

  const isDoubleColumn = primaryNavLayout === 'double'
  const actualProducts = isDoubleColumn && productsLength > 2 ? products.slice(0, 2) : products

  if (isDisabled) return null

  return (
    <div
      className={cn(
        'col-span-full border-t border-beige-dark/50 pt-3 md:col-span-12 md:col-start-9 md:my-8 md:border-none md:pt-0',
        {
          'md:col-span-8 md:col-start-13': primaryNavLayout === 'double',
        },
        className,
      )}
    >
      {title && <h3 className="mb-5">{title}</h3>}
      <ul
        className={cn('grid-container ml-0 grid-cols-6 gap-y-4 md:grid-cols-12', {
          'md:col-start-13 md:grid-cols-8': primaryNavLayout === 'double',
        })}
      >
        {actualProducts?.map((product, index) => (
          <li
            key={index}
            className={cn('col-span-3 md:col-span-4', {
              'md:col-span-4': primaryNavLayout === 'double',
            })}
          >
            <ProductCard data={product} variant="condensed" />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default FeatureProducts
