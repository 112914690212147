import CallToAction from '@/components/shared/call-to-action'
import { cn } from '@/lib/utils'
import { FooterPayload, HeaderOrFooterDropdown } from '@/types'

import NavDropdown from '../nav-dropdown'

type FooterNavProps = {
  menus?: FooterPayload['footerMenus']
}

const FooterTwoColumnsNav = ({ links }) => {
  if (!links?.length) return null
  const firstColumn = links.slice(0, Math.ceil(links.length / 2))
  const secondColumn = links.slice(Math.ceil(links.length / 2))

  return (
    <div className="hidden md:block md:columns-2">
      <ul className="ml-0 break-inside-avoid space-y-2.5">
        {firstColumn.map((link, linkIndex) => {
          if (!link) return null

          return link._type === 'dropdown' ? (
            <FooterNavDropdown key={linkIndex} data={link} />
          ) : (
            <li key={linkIndex}>
              <CallToAction className="hover-underline h4" {...link} />
            </li>
          )
        })}
      </ul>
      <ul className="ml-0 break-inside-avoid space-y-2.5">
        {secondColumn.map((link, linkIndex) => {
          if (!link) return null

          return link._type === 'dropdown' ? (
            <FooterNavDropdown key={linkIndex} data={link} />
          ) : (
            <li key={linkIndex} className="flex justify-start">
              <CallToAction className="hover-underline h4" {...link} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const FooterNavDropdown = ({ data }: { data: HeaderOrFooterDropdown }) => {
  const { title, links } = data

  return (
    <NavDropdown title={title}>
      <ul className="ml-0 block items-start gap-y-1 overflow-hidden pt-4">
        {links?.map((link, index) => (
          <li className="flex" key={index}>
            <CallToAction {...link} className="paragraph hover-underline text-21" />
          </li>
        ))}
      </ul>
    </NavDropdown>
  )
}

const FooterNav = ({ menus }: FooterNavProps) => {
  if (!menus?.length) return null

  return (
    <>
      <nav className="grid-container container hidden gap-y-6 md:grid md:auto-rows-auto md:items-start md:gap-y-0">
        {menus.map((menu, menuIndex) => {
          const { subtitle, links } = menu

          if (!links?.length) return null

          return (
            <div
              key={menuIndex}
              className={cn('col-span-full', {
                'md:col-span-10 xl:col-span-8 xl:col-start-2': menuIndex === 0,
                'md:row-span-3': menuIndex === 0 || menuIndex === 1,
                'md:col-start-11': menuIndex === 1,
                'md:col-span-5': menuIndex > 0,
                'md:col-start-16': menuIndex > 1,
                'md:mt-12': menuIndex === 3,
              })}
            >
              {subtitle && <h3 className="h3 mb-3">{subtitle}</h3>}
              <ul
                className={cn('ml-0 first-of-type:mt-0', {
                  'space-y-2.5 md:hidden': menuIndex === 0,
                  'space-y-2': menuIndex > 0,
                })}
              >
                {links.map((link, linkIndex) => {
                  if (!link) return null

                  return link._type === 'dropdown' ? (
                    <FooterNavDropdown key={linkIndex} data={link} />
                  ) : (
                    <li key={linkIndex}>
                      <CallToAction
                        className={cn('hover-underline', {
                          h4: menuIndex === 0,
                          paragraph: menuIndex > 0,
                        })}
                        {...link}
                      />
                    </li>
                  )
                })}
              </ul>
              {/* additional desktop menu which has 2 fixed items columns */}
              {menuIndex === 0 && <FooterTwoColumnsNav links={links} />}
            </div>
          )
        })}
      </nav>
    </>
  )
}

export default FooterNav
