import CallToAction from '@/components/shared/call-to-action'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import Icon from '@/components/ui/icon'
import { FooterPayload } from '@/types'

type MobileFooterNavProps = {
  menus?: FooterPayload['footerMenus']
}

const MobileFooterNav = ({ menus }: MobileFooterNavProps) => {
  if (!menus?.length) return null

  const firstAccordionValue =
    menus[0].links?.[0]?._type === 'dropdown' ? `item-${menus[0].links[0].title}-0` : null

  return (
    <div className="md:hidden">
      {menus[0].links?.map((linkItem, linkIndex) =>
        linkItem._type === 'dropdown' ? (
          <Accordion
            key={linkIndex}
            type="multiple"
            className="flex w-full flex-col gap-7 py-5"
            defaultValue={firstAccordionValue ? [firstAccordionValue] : undefined}
          >
            <AccordionItem
              key={`item-${linkItem.title}-${linkIndex}`}
              value={`item-${linkItem.title}-${linkIndex}`}
              className="!border-b-0"
            >
              <AccordionTrigger
                className="nav-text w-full"
                customIcon={
                  <div className="absolute right-[30px] shrink-0">
                    <Icon variant="arrow" />
                  </div>
                }
              >
                <div className="relative flex h-full w-full items-center gap-4">
                  <p className="absolute left-0 right-0 mx-auto w-fit bg-beige px-4 font-bold">
                    {linkItem.title}
                  </p>
                  <div className="h-[1px] w-full grow-[1px] bg-beige-medium"></div>
                </div>
              </AccordionTrigger>
              {linkItem.links.map((dropdownLink, dropdownLinkIndex) => (
                <AccordionContent key={dropdownLinkIndex} className="flex flex-col gap-[10px] pb-0">
                  <li className="list-none text-center">
                    <CallToAction
                      className="h4 leading-[40px] text-almost-black"
                      {...dropdownLink}
                    />
                  </li>
                </AccordionContent>
              ))}
            </AccordionItem>
          </Accordion>
        ) : (
          <li key={linkIndex} className="list-none text-center">
            <CallToAction className="h4 leading-[30px]" {...linkItem} />
          </li>
        ),
      )}
    </div>
  )
}

export default MobileFooterNav
