import CallToAction from '@/components/shared/call-to-action'
import Media from '@/components/shared/media'
import Button from '@/components/ui/button'
import { cn } from '@/lib/utils'
import type { CallToActionType, MediaOnly } from '@/types'

type FeatureCardProps = {
  title?: string
  copy?: string
  media?: MediaOnly
  link: CallToActionType
  className?: string
  primaryNavLayout: 'single' | 'double'
}

const FeatureCard = ({
  title,
  copy,
  media,
  link,
  className,
  primaryNavLayout,
}: FeatureCardProps) => {
  return (
    <CallToAction
      className={cn(
        'group col-span-full max-w-sm border-t border-beige-dark/50 pt-3 md:col-span-9 md:col-start-12 md:my-8 md:max-w-none md:border-none md:pt-0 xl:col-span-11 xl:col-start-11 short-desktop:col-span-9 short-desktop:col-start-12',
        className,
      )}
      {...link}
      withoutLabel={true}
    >
      <Media className="mb-4" data={media} aspectRatio="16/9" borderOverride="border-padding" />
      <div className="grid items-start gap-2 xl:grid-cols-[1fr_105px] xl:gap-5">
        <div className="paragraph">
          {title && <h3 className="paragraph inline font-bold normal-case">{title}</h3>}
          {copy && <p className="paragraph inline"> — {copy}</p>}
        </div>
        {link.linkType !== 'none' && (
          <Button
            variant="tertiary"
            asChild
            className="justify-self-start whitespace-nowrap xl:justify-self-end"
          >
            <span>{link?.label ? link.label : 'Read more'}</span>
          </Button>
        )}
      </div>
    </CallToAction>
  )
}

export default FeatureCard
