'use client'

import 'react-h5-audio-player/lib/styles.css'
import './audio-player.css'

import { Transition } from '@headlessui/react'
import Image from 'next/image'
import { useRef, useState } from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'

import useStore from '@/store'

const LocalAudioPlayer = () => {
  const player = useRef<AudioPlayer | null>(null)
  const [isPlaying, setIsPlaying] = useState(true)

  const setAudioPlayerOpened = useStore((state) => state.setAudioPlayerOpened)
  const isAudioPlayerOpened = useStore((state) => state.isAudioPlayerOpened)
  const isAudioPlayerUrl = useStore((state) => state.isAudioPlayerUrl)

  function togglePlayingHandler() {
    if (player.current?.audio?.current) {
      if (isPlaying) {
        setIsPlaying(false)
        player.current.audio.current.pause()
      } else {
        setIsPlaying(true)
        player.current.audio.current.play()
      }
    }
  }

  return (
    <div className={`fixed bottom-0 left-0 z-50 w-full`}>
      <Transition
        show={isAudioPlayerOpened}
        enter="transition-transform duration-500"
        enterFrom="translate-y-20"
        enterTo="translate-y-0"
        leave="transition-transform duration-500"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-20"
      >
        <div className="flex flex-col bg-[#F0ECE1] text-black">
          <div className="inline-flex h-2 w-full flex-col items-center justify-start gap-[3px]">
            <div className="h-1 w-full bg-[#c4bfaf]" />
            <div className="h-px w-full bg-[#c4bfaf]" />
          </div>
          <div className="flex items-center space-x-4 px-4 py-3">
            <button onClick={togglePlayingHandler} className="h-[30px] w-[30px] bg-white/80 p-1">
              <Image
                src={
                  isPlaying
                    ? '/assets/icons/audio-player/pause.svg'
                    : '/assets/icons/audio-player/play.svg'
                }
                width={20}
                height={20}
                alt=""
              />
            </button>

            <div className="flex h-4 w-full items-center">
              <AudioPlayer
                autoPlay
                ref={player}
                src={isAudioPlayerUrl}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                customControlsSection={[]}
                showJumpControls={false}
                showFilledProgress={true}
                customProgressBarSection={[
                  RHAP_UI.PROGRESS_BAR,
                  RHAP_UI.CURRENT_TIME,
                  RHAP_UI.DURATION,
                ]}
                onPlay={() => {
                  setIsPlaying(true)
                }}
                onEnded={togglePlayingHandler}
                style={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                }}
                className="custom-player z-50"
              />
            </div>
            <button
              onClick={() => {
                setIsPlaying(false)
                setAudioPlayerOpened(false, isAudioPlayerUrl)
              }}
              className="w-6"
            >
              <Image
                src="/assets/icons/audio-player/player-close.svg"
                width={24}
                height={24}
                alt="Close"
              />
            </button>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default LocalAudioPlayer
