import Link from 'next/link'
import { forwardRef, useEffect, useState } from 'react'
import { RemoveScroll } from 'react-remove-scroll'
import { Mousewheel } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import CallToAction from '@/components/shared/call-to-action'
import Media from '@/components/shared/media'
import useMediaQuery from '@/hooks/use-media-query'
import { cn } from '@/lib/utils'
import { resolveHref } from '@/sanity/lib/utils'
import { HeaderPayload } from '@/types'

type ExploreNavProps = {
  data: HeaderPayload['explore']
  isOpened: boolean
  closeHandler: () => void
  clickHandler: (e: React.MouseEvent) => void
}

type Ref = HTMLDivElement

const ExploreNav = forwardRef<Ref, ExploreNavProps>(
  ({ data, isOpened, closeHandler, clickHandler }, ref) => {
    const { viewAll, links, featuredPosts } = data
    const [activeMediaIndex, setActiveMediaIndex] = useState(0)
    const hasHover = useMediaQuery('(hover: hover)')

    useEffect(() => {
      if (!hasHover && isOpened) {
        const randomIndex = Math.floor(Math.random() * (links?.length ?? 0))
        setActiveMediaIndex(randomIndex)
      }
    }, [hasHover, isOpened, links])

    return (
      <RemoveScroll forwardProps enabled={isOpened}>
        <nav
          className={cn(
            'invisible fixed inset-0 z-header-nav max-h-full bg-black pt-header-height opacity-0 transition-opacity duration-500 lg:pb-8',
            RemoveScroll.classNames.fullWidth,
            {
              'visible opacity-100': isOpened,
            },
          )}
        >
          <div className="pointer-events-none fixed inset-0 -z-[1] w-screen after:absolute after:inset-0 after:z-10 after:block after:bg-deep-black/60">
            {links?.map((link, index) => (
              <Media
                key={index}
                data={link.backgroundMedia}
                className={cn('absolute inset-0 transition-opacity duration-500', {
                  'invisible opacity-0': index !== activeMediaIndex,
                })}
                sizes="100vw"
              />
            ))}
          </div>
          <button
            className="flex w-full items-center gap-x-3 border-b border-beige-dark/50 px-container py-3 font-sans text-base text-off-white lg:hidden"
            onClick={closeHandler}
          >
            <svg
              width="11"
              height="12"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Arrow head"
                d="M10.4273 1.28565L0.999245 5.99978L10.4273 10.7134L8.07031 5.99978L10.4273 1.28565Z"
                fill="currentColor"
                stroke="currentColor"
                strokeLinejoin="round"
              />
            </svg>
            Explore
          </button>
          <div
            ref={ref}
            className="flex h-full max-h-[calc(100%-50px)] flex-col overflow-y-auto overflow-x-hidden pb-5 md:max-h-none"
            onClick={clickHandler}
          >
            {!!links?.length && (
              <ul className="relative z-20 my-auto ml-0 flex flex-col gap-y-2 px-[var(--container-padding)] sm:items-center has-hover:[&:has(a:hover)_a]:opacity-60">
                {links.map((link, index) => (
                  <li key={index}>
                    <CallToAction
                      {...link.link}
                      onMouseEnter={hasHover ? () => setActiveMediaIndex(index) : undefined}
                      className="text-[clamp(2rem,5vh,3.25rem)] leading-1.2 text-beige duration-300 [&&]:hover:opacity-100"
                    />
                  </li>
                ))}
                {viewAll && (
                  <li className="mt-[3vh] md:hidden">
                    <CallToAction
                      {...viewAll}
                      className="nav-text-small relative z-20 inline-block text-off-white"
                    />
                  </li>
                )}
              </ul>
            )}

            <div className="relative mt-5 text-off-white">
              <div className="container flex items-baseline gap-4">
                <p className="paragraph text-21">The story continues</p>
                {viewAll && (
                  <CallToAction
                    {...viewAll}
                    className="nav-text-small hover-underline relative z-20 hidden text-off-white md:inline-block"
                  />
                )}
              </div>
              {/* Desktop version with 3 columns */}
              <div className="container mt-3 hidden w-full items-start justify-between md:flex lg:mt-4 xl:mt-5">
                {featuredPosts?.slice(0, 3).map((post, i) => {
                  return (
                    <Link
                      key={post._id + '-' + i}
                      href={resolveHref(post)}
                      className={cn(
                        'group grid w-[30vw] max-w-sm grid-cols-[30%_auto] gap-x-3 md:grid-cols-2 xl:max-w-md 2xl:max-w-lg',
                        {
                          'border-l border-r-beige-dark/50 pl-6': i !== 0,
                          'pr-6': i !== 2,
                        },
                      )}
                    >
                      <Media data={post.featuredMedia} aspectRatio="7/5" />
                      <div className="flex flex-col items-start gap-y-2 self-start">
                        {post.category && (
                          <span className="tag">{post.category.split('-').join(' ')}</span>
                        )}
                        <h3 className="paragraph text-21 normal-case">
                          <span className="hover-underline group-hover-underline">
                            {post.title}
                          </span>
                        </h3>
                      </div>
                    </Link>
                  )
                })}
              </div>

              {/* Mobile version with swiper  */}
              <div className="md:hidden">
                {!!featuredPosts?.slice(0, 3).length && (
                  <Swiper
                    slidesPerView="auto"
                    speed={500}
                    spaceBetween={12}
                    className="mt-3"
                    breakpoints={{
                      768: {
                        spaceBetween: 24,
                      },
                    }}
                    modules={[Mousewheel]}
                    mousewheel={{
                      enabled: true,
                      // sensitivity: 1,
                      // thresholdDelta: 1,
                      // thresholdTime: 100,
                    }}
                    loop
                  >
                    {featuredPosts.map((post, i) => {
                      return (
                        <SwiperSlide
                          key={post._id + '-' + i}
                          className="pl-5 [&&]:flex [&&]:h-auto [&&]:w-auto"
                        >
                          <Link
                            href={resolveHref(post)}
                            className={cn(
                              'group grid w-[81vw] max-w-sm grid-cols-[40%_auto] gap-x-3 border-r border-r-beige-dark/50 pr-4 sm:pr-6 md:grid-cols-2 xl:max-w-md 2xl:max-w-lg',
                            )}
                          >
                            <Media
                              data={post.featuredMedia}
                              className="shrink-0"
                              aspectRatio="square"
                            />
                            <div className="flex flex-col items-start gap-y-2 self-start">
                              {post.category && <span className="tag">{post.category}</span>}
                              <h3 className="paragraph text-21 normal-case">
                                <span className="hover-underline group-hover-underline">
                                  {post.title}
                                </span>
                              </h3>
                            </div>
                          </Link>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                )}
              </div>
            </div>
          </div>
        </nav>
      </RemoveScroll>
    )
  },
)

ExploreNav.displayName = 'ExploreNav'

export default ExploreNav
