import Image from 'next/image'

import { cn } from '@/lib/utils'

const CloseButton = ({ className, onClick }: { className?: string; onClick: () => void }) => {
  return (
    <button
      className={cn('nav-text-small group z-20 ml-auto flex items-center gap-2.5', className)}
      onClick={onClick}
    >
      <Image
        className="transition-transform duration-300 group-hover:rotate-180"
        src="/assets/icons/icon-close.svg"
        width="10"
        height="10"
        alt="Close"
      />
      Close
    </button>
  )
}

export default CloseButton
