'use client'

import Image from 'next/image'
import { usePathname } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { Image as SanityImageType } from 'sanity'

import NewsletterTray from '@/components/modules/newsletter/newsletter-tray'
import Media from '@/components/shared/media'
import Divider from '@/components/ui/divider'
import useIsOnscreen from '@/hooks/use-is-onscreen'
import { cn } from '@/lib/utils'
import { resolveHref } from '@/sanity/lib/utils'
import { MediaOnly } from '@/types'

type PreFooterProps = {
  media?: MediaOnly
  logoOverlay?: SanityImageType
  alternatePreFooterPaths?: {
    _type: string
    category?: string
    slug?: string
    parentSlug?: string
  }[]
}

const PreFooter = ({ media, logoOverlay, alternatePreFooterPaths }: PreFooterProps) => {
  const pathname = usePathname()
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useIsOnscreen({
    ref,
    once: false,
  })

  if (pathname.startsWith('/gifting/your-e-voucher')) {
    return null
  }

  const isPathMatched = alternatePreFooterPaths?.some((path) => {
    const resolvedPath = resolveHref(path)
    return pathname.includes(resolvedPath)
  })

  if (isPathMatched) {
    return (
      <div className="border-t-2 border-black">
        <div className="my-[60px] flex w-full items-center justify-center md:my-20">
          <Image
            src="/assets/footer/from-bombay.svg"
            width="253"
            height="65"
            alt="From Bombay with Love"
          />
        </div>
        <div className="grid-container container">
          <div className="col-span-full col-start-1 md:col-start-2 md:col-end-19">
            <Divider type="outline-diamond" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={cn('relative z-footer bg-beige', {
        hidden: pathname === '/gifting/your-e-voucher',
      })}
    >
      <div className="md:hidden">
        <div className="relative h-[390px] w-full">
          <Media data={media} className="h-full w-full" />
          <div
            className="grid-container container absolute inset-0 justify-items-center md:items-center"
            ref={ref}
          >
            {logoOverlay && (
              <Image
                src="https://cdn.sanity.io/images/daku84np/production/eb78d4a0ab3a682436a9c4725bab56a6c4e2b67e-253x65.svg"
                className="col-span-full row-start-1 mt-10 w-[250px] md:col-span-5 md:col-start-2 md:mb-20 md:mt-0 md:w-full"
                width="253"
                height="65"
                alt="From Bombay with Love"
              />
            )}
          </div>
        </div>
        <div className="mb-[2px] md:hidden">
          <Divider type="7-line" />
        </div>
        <div className="grid-container">
          <NewsletterTray />
        </div>
      </div>

      {/* Desktop View */}
      <div
        className={cn(
          'relative z-footer grid hidden min-h-[390px] max-w-full overflow-hidden md:block md:min-h-[500px] xl:min-h-[600px]',
        )}
      >
        <Media
          data={media}
          className="absolute inset-0 after:absolute after:inset-0 after:bg-deep-black/30"
        />
        <div
          className="grid-container container relative z-10 justify-items-center md:items-center"
          ref={ref}
        >
          {logoOverlay && (
            <Image
              src="https://cdn.sanity.io/images/daku84np/production/eb78d4a0ab3a682436a9c4725bab56a6c4e2b67e-253x65.svg"
              className="col-span-full row-start-1 mt-10 w-[250px] md:col-span-5 md:col-start-2 md:mb-20 md:mt-0 md:w-full"
              width="253"
              height="65"
              alt="From Bombay with Love"
            />
          )}
          <NewsletterTray
            className={cn({
              'md:translate-x-0': isVisible,
            })}
          />
        </div>
      </div>
    </div>
  )
}

export default PreFooter
