'use client'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

import { cn } from '@/lib/utils'
import useStore from '@/store'
import type { BookingPayload } from '@/types'

import CloseButton from '../ui/close-button'
import SideModal from '../ui/side-modal'

const Widget = ({ sevenroomsWidgets }: BookingPayload) => {
  const isBookingOpened = useStore((state) => state.isBookingOpened)
  const toggleBooking = useStore((state) => state.toggleBooking)

  const pathname = usePathname()

  const closeBooking = () => {
    toggleBooking()
  }

  const onCloseHandler = () => {
    closeBooking()
  }

  const widgetUrl = useMemo(() => {
    const cafesPathnames = [
      '/battersea/',
      '/birmingham/',
      '/canary-wharf/',
      '/carnaby/',
      '/covent-garden/',
      '/edinburgh/',
      '/kensington/',
      '/kings-cross/',
      '/manchester/',
      '/shoreditch/',
    ]

    const url = sevenroomsWidgets.isOldWidget
      ? sevenroomsWidgets.generalOldWidgetUrl
      : sevenroomsWidgets.generalWidgetUrl

    if (!cafesPathnames.includes(pathname) || !sevenroomsWidgets.locations?.length) {
      return url
    }

    const cafeData = sevenroomsWidgets.locations.find((loc) => {
      return loc.location.slug === pathname.replaceAll('/', '')
    })

    if (!cafeData) {
      return url
    }

    return sevenroomsWidgets.isOldWidget ? cafeData.oldWidgetUrl : cafeData.widgetUrl
  }, [
    pathname,
    sevenroomsWidgets.generalOldWidgetUrl,
    sevenroomsWidgets.generalWidgetUrl,
    sevenroomsWidgets.isOldWidget,
    sevenroomsWidgets.locations,
  ])

  return (
    <SideModal isOpen={isBookingOpened} onClose={onCloseHandler} className="flex flex-col">
      <div
        className={cn('px-5 py-7', {
          'absolute inset-x-0 top-0': !sevenroomsWidgets.isOldWidget,
        })}
      >
        <CloseButton onClick={closeBooking} />
      </div>
      <div className="flex-grow">
        {isBookingOpened && <iframe key={widgetUrl} src={widgetUrl} className="h-full w-full" />}
      </div>
    </SideModal>
  )
}

export default Widget
