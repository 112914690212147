'use client'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { useEffect, useRef, useState } from 'react'

import FooterNav from '@/components/layout/footer/footer-nav'
import Social from '@/components/layout/footer/social'
import CallToAction from '@/components/shared/call-to-action'
import Media from '@/components/shared/media'
import useTailwindBreakpoint from '@/hooks/use-tailwind-breakpoint'
import { cn } from '@/lib/utils'
import { FooterPayload } from '@/types'

import Dice from './dice'
import MobileFooterNav from './mobile-footer-nav'

gsap.registerPlugin(ScrollToPlugin)

const FooterContainer = ({
  footerMenus,
  mobileFooterMenus,
  socialLinks,
  legalLinks,
  legalMessage,
  subFooter,
}: FooterPayload) => {
  const [showSubFooter, setShowSubfooter] = useState(false)
  const subFooterRef = useRef<HTMLDivElement>(null)
  const isDesktop = useTailwindBreakpoint('md')

  useEffect(() => {
    if (showSubFooter) {
      const headerHeight =
        parseFloat(
          getComputedStyle(document.documentElement).getPropertyValue('--header-height'),
        ) || 0
      const targetPosition = !isDesktop
        ? window.scrollY + window.innerHeight * 0.6
        : window.scrollY + window.innerHeight - headerHeight
      gsap.to(window, {
        scrollTo: { y: targetPosition, autoKill: false },
        duration: 5,
        ease: 'power4.inOut',
      })
    }
  }, [showSubFooter, isDesktop])

  return (
    <div className="relative z-footer">
      <footer className="bg-beige pt-6 md:pb-12 md:pt-10 lg:pt-16 print:hidden">
        {/* <Divider type="thick" className="absolute inset-x-0 top-0 md:hidden" /> */}
        <div className="top-header-height bg-beige [&&]:sticky">
          <FooterNav menus={footerMenus} />
          <MobileFooterNav menus={mobileFooterMenus} />

          <div className="grid-container container mt-6 gap-y-12 md:mt-12 md:items-start md:gap-y-12 lg:mt-16 lg:gap-y-16">
            <Social links={socialLinks} />

            {!!legalLinks?.length && (
              <div className="md:col-end-21 col-span-full flex flex-col items-center gap-y-2 md:col-start-7 md:items-end xl:col-start-11">
                <ul className="ml-0 flex w-full flex-col items-center gap-y-1.5 md:flex-row md:items-baseline md:justify-end md:gap-x-4">
                  <li>
                    <p className="italic">{legalMessage}</p>
                  </li>
                  {legalLinks.map((link, index) => (
                    <li key={index}>
                      <CallToAction className="body hover-underline" {...link} />
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {/* <Dice showSubfooter={() => setShowSubfooter(true)} /> */}
          </div>
        </div>
      </footer>
      <div
        ref={subFooterRef}
        className={cn(
          'absolute -bottom-0 -z-[1] h-[60vh] bg-white md:bottom-0 md:h-screen [&&]:sticky',
          {
            hidden: !showSubFooter,
          },
        )}
      >
        <Media
          data={subFooter?.media}
          className="after:absolute after:inset-0 after:bg-gradient-to-b after:from-[black]/40 after:to-[black]/40"
        />
        <div className="container absolute bottom-8 left-0 md:bottom-[74px]">
          <p className="prose-text text-white md:max-w-[400px]">{subFooter?.text}</p>
        </div>
      </div>
    </div>
  )
}

export default FooterContainer
