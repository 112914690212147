import { useState } from 'react'

import CallToAction from '@/components/shared/call-to-action'
import useTailwindBreakpoint from '@/hooks/use-tailwind-breakpoint'
import { cn } from '@/lib/utils'
import { CallToActionType } from '@/types'

type SecondaryNavProps = {
  links: CallToActionType[]
  hideCTAs?: boolean
  className?: string
}

const SecondaryNav = ({ links, hideCTAs, className }: SecondaryNavProps) => {
  // const isLg = useTailwindBreakpoint('lg')
  // const [isExpanded, setIsExpanded] = useState(false)
  // const collapsedLinksCount = hideCTAs ? 5 : 3
  // const currentLinks = !isLg
  //   ? links
  //   : links?.slice(0, isExpanded ? links.length : collapsedLinksCount)

  // const toggleExpanded = () => setIsExpanded(!isExpanded)

  return (
    <ul
      className={cn(
        'col-span-full ml-0 mt-4 flex max-w-full flex-col space-y-2 border-t border-beige-dark/50 py-2 lg:mt-0 lg:flex-row lg:space-y-0 lg:overflow-x-auto lg:border-t-0 lg:scrollbar-hide 2xl:overflow-visible',
        className,
      )}
    >
      {links.map((link, index) => (
        <li
          key={index}
          className={cn(
            'flex items-center lg:before:mx-4 lg:before:block lg:before:h-full lg:before:border-r lg:before:border-beige-dark/50',
            {
              'lg:before:hidden': hideCTAs && index === 0,
            },
          )}
        >
          <CallToAction
            {...link}
            className="paragraph hover-underline text-nowrap [&&]:no-underline"
          />
        </li>
      ))}
      {/* {!!(links?.length > collapsedLinksCount && isLg) && (
        <li className="hidden items-center lg:flex lg:before:mx-4 lg:before:block lg:before:h-full lg:before:border-r lg:before:border-beige-dark/50">
          <button
            className="paragraph hover-underline text-nowrap [&&]:no-underline"
            onClick={toggleExpanded}
          >
            {isExpanded ? 'Less...' : 'More...'}
          </button>
        </li>
      )} */}
    </ul>
  )
}

export default SecondaryNav
