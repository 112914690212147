import { forwardRef, RefObject, useEffect } from 'react'
import { RemoveScroll } from 'react-remove-scroll'

// import { clearBodyLocks, lock } from 'tua-body-scroll-lock'
import { cn } from '@/lib/utils'
import useStore from '@/store'

type SideModalProps = {
  isOpen: boolean
  onClose?: () => void
  className?: string
  children: React.ReactNode
}

const SideModal = forwardRef<HTMLDivElement, SideModalProps>(
  ({ isOpen, onClose, className, children }, ref) => {
    const setIsHeaderTransparent = useStore((state) => state.setIsHeaderTransparent)

    useEffect(() => {
      if (isOpen) {
        setIsHeaderTransparent(!isOpen)
      }
    }, [isOpen, setIsHeaderTransparent, ref])

    return (
      <RemoveScroll forwardProps enabled={isOpen}>
        <div
          className={cn(
            'grid-container fixed inset-0 z-booking bg-beige-medium transition-opacity duration-500',
            {
              'visible opacity-100': isOpen,
              'invisible opacity-0': !isOpen,
            },
          )}
          onClick={onClose}
        >
          <div
            className={cn(
              'relative col-span-full ml-auto h-full w-full transform overflow-y-auto border-l border-l-black bg-beige transition-transform duration-500',
              isOpen ? 'translate-x-0' : 'translate-x-full',
              'lg:w-[650px]',
              className,
            )}
            ref={ref || undefined}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      </RemoveScroll>
    )
  },
)

SideModal.displayName = 'SideModal'

export default SideModal
