import CallToAction from '@/components/shared/call-to-action'
import Button, { BookingButton } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import type { CallToActionType } from '@/types'

type MobileBottomBarProps = {
  isOpened: boolean
  secondaryCta?: CallToActionType
}

const MobileBottomBar = ({ secondaryCta, isOpened }: MobileBottomBarProps) => {
  return (
    <div
      className={cn(
        'container invisible fixed inset-x-0 bottom-0 z-header-nav grid min-h-header-mobile-bottom-bar-height translate-y-full grid-cols-2 gap-2.5 border-b border-t border-beige-dark/50 bg-beige py-2.5 transition-all duration-500 lg:mr-2.5 lg:hidden',
        {
          'visible translate-y-0': isOpened,
        },
      )}
    >
      <BookingButton size="sm">Book a table</BookingButton>
      {secondaryCta && (
        <Button variant="secondary" size="sm" asChild>
          <CallToAction {...secondaryCta} />
        </Button>
      )}
    </div>
  )
}

export default MobileBottomBar
