import CallToAction from '@/components/shared/call-to-action'
import Button, { BookingButton } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import type { CallToActionType } from '@/types'

import SecondaryNav from './secondary-nav'

type BottomBarProps = {
  links?: CallToActionType[]
  secondaryCta?: CallToActionType
  hideCTAs?: boolean
}

const BottomBar = ({ links = [], secondaryCta, hideCTAs }: BottomBarProps) => {
  return (
    <div className="mt-auto hidden min-h-header-mobile-bottom-bar-height w-full border-b border-t border-beige-dark/50 bg-beige py-2.5 lg:block">
      <div className="container flex h-full">
        <div
          className={cn(
            'grid w-full flex-shrink-0 grid-cols-2 gap-2.5 lg:mr-2.5 lg:flex lg:w-auto',
            {
              'lg:hidden': hideCTAs,
            },
          )}
        >
          <BookingButton size="sm">Book a table</BookingButton>
          {secondaryCta && (
            <Button variant="secondary" size="sm" asChild>
              <CallToAction {...secondaryCta} />
            </Button>
          )}
        </div>
        <SecondaryNav links={links} hideCTAs={hideCTAs} />
      </div>
    </div>
  )
}

export default BottomBar
