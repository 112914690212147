import { forwardRef } from 'react'
import { RemoveScroll } from 'react-remove-scroll'

import CallToAction from '@/components/shared/call-to-action'
import { cn } from '@/lib/utils'
import type { CallToActionType, HeaderNav, HeaderOrFooterDropdown } from '@/types'

import NavDropdown from '../nav-dropdown'
import BottomBar from './bottom-bar'
import FeatureCard from './feature-card'
import FeatureProducts from './feature-products'
import SecondaryNav from './secondary-nav'

type SubNavProps = {
  title: string
  data: HeaderNav
  isOpened: boolean
  secondaryCta?: CallToActionType
  closeHandler: () => void
  clickHandler: (e: React.MouseEvent) => void
}

type Ref = HTMLDivElement

const HeaderNavDropdown = ({ data }: { data: HeaderOrFooterDropdown }) => {
  const { title, links } = data

  return (
    <NavDropdown title={title} className="pt-4">
      <ul className="ml-0 block items-start gap-y-1 overflow-hidden pt-4">
        {links?.map((link, index) => (
          <li className="flex" key={index}>
            <CallToAction {...link} className="paragraph hover-underline text-21" />
          </li>
        ))}
      </ul>
    </NavDropdown>
  )
}

const SubNav = forwardRef<Ref, SubNavProps>(
  ({ data, title, isOpened, secondaryCta, closeHandler, clickHandler }, ref) => {
    const {
      secondaryNav,
      featureCard,
      primaryNavLayout,
      primaryNav,
      hideSecondaryNavCTAs,
      featureProducts,
      featureType,
    } = data

    const linkClasses =
      "h4 mt-4 relative inline-block leading-[1.2] transition-transform duration-300 before:absolute before:right-full before:h-full before:bg-[length:40px_auto] before:w-[50px] before:bg-[url('/assets/icons/icon-nav-arrow.svg')] before:bg-left before:bg-no-repeat hover:translate-x-14"

    return (
      <RemoveScroll forwardProps enabled={isOpened}>
        <nav
          className={cn(
            'invisible fixed inset-0 z-header-nav flex h-full max-h-full translate-x-full flex-col bg-beige pb-header-mobile-bottom-bar-height pt-header-height opacity-0 transition-[transform,opacity,visibility] duration-500 lg:max-h-[800px] lg:translate-x-0 lg:pb-0',
            RemoveScroll.classNames.fullWidth,
            {
              'visible translate-x-0 opacity-100': isOpened,
            },
          )}
          onClick={clickHandler}
        >
          <button
            className="flex min-h-12 items-center gap-x-3 border-b border-beige-dark/50 px-container py-2.5 font-sans text-base lg:hidden"
            onClick={closeHandler}
          >
            <svg
              width="11"
              height="12"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Arrow head"
                d="M10.4273 1.28565L0.999245 5.99978L10.4273 10.7134L8.07031 5.99978L10.4273 1.28565Z"
                fill="currentColor"
                stroke="currentColor"
                strokeLinejoin="round"
              />
            </svg>
            {title}
          </button>
          <div
            className="grid-container container h-full max-h-full items-start overflow-y-auto pb-10 md:overflow-hidden"
            ref={ref}
          >
            <div
              className={cn(
                'relative col-span-6 md:col-span-7 lg:before:pointer-events-none lg:before:absolute lg:before:top-0 lg:before:z-10 lg:before:h-10 lg:before:w-full lg:before:bg-gradient-to-b lg:before:from-beige lg:before:to-transparent lg:after:pointer-events-none lg:after:absolute lg:after:bottom-0 lg:after:z-10 lg:after:h-10 lg:after:w-full lg:after:bg-gradient-to-t lg:after:from-beige lg:after:to-transparent',
                {
                  'md:col-span-11 xl:col-span-10': primaryNavLayout === 'double',
                },
              )}
            >
              <div className="relative overflow-hidden py-8 lg:max-h-[calc(max(min(100vh,750px))-var(--header-mobile-bottom-bar-height)-90px)] lg:overflow-y-auto lg:scrollbar-hide">
                {primaryNav?.map((nav, index) => (
                  <div key={index} className="break-inside-avoid">
                    {nav?.subtitle && (
                      <h3
                        className={cn('h3 mb-2', {
                          'mt-10': index > 0,
                        })}
                      >
                        {nav.subtitle}
                      </h3>
                    )}
                    <ul
                      className={cn('ml-0', {
                        'columns-2 gap-x-2': primaryNavLayout === 'double',
                      })}
                    >
                      {nav?.links?.map((link, index) =>
                        link._type === 'dropdown' ? (
                          <HeaderNavDropdown key={index} data={link} />
                        ) : (
                          <li key={index} className="flex overflow-hidden">
                            <CallToAction {...link} className={linkClasses} />
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            {featureType === 'card' && featureCard && (
              <FeatureCard {...featureCard} primaryNavLayout={primaryNavLayout} />
            )}
            {featureType === 'products' && featureProducts && (
              <FeatureProducts {...featureProducts} primaryNavLayout={primaryNavLayout} />
            )}

            {secondaryNav && <SecondaryNav className="lg:hidden" links={secondaryNav} />}
          </div>
          <BottomBar
            links={secondaryNav}
            secondaryCta={secondaryCta}
            hideCTAs={hideSecondaryNavCTAs}
          />
        </nav>
      </RemoveScroll>
    )
  },
)

SubNav.displayName = 'SubNav'

export default SubNav
