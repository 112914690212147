import { forwardRef } from 'react'
import { RemoveScroll } from 'react-remove-scroll'
import { Mousewheel } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import BlogCard from '@/components/shared/blog-card'
import { cn } from '@/lib/utils'
import { Post } from '@/types'

// import SearchBar from './search-bar'

type MobileNavProps = {
  isOpened: boolean
  children: React.ReactNode
  featuredPosts?: Post[]
}

type Ref = HTMLDivElement

const MobileNav = forwardRef<Ref, MobileNavProps>(({ isOpened, children, featuredPosts }, ref) => {
  return (
    <RemoveScroll forwardProps enabled={isOpened}>
      <nav
        className={cn(
          'invisible fixed inset-0 z-header-nav flex h-full max-h-full flex-col overflow-y-auto bg-beige pb-header-mobile-bottom-bar-height pt-header-height opacity-0 transition-opacity duration-500 lg:hidden lg:max-h-[750px]',
          {
            'visible opacity-100': isOpened,
          },
        )}
      >
        {/* <SearchBar /> */}
        <div className="max-h-full overflow-y-auto overflow-x-hidden" ref={ref}>
          <div className="container">{children}</div>

          {!!featuredPosts?.length && (
            <div className="grid-container mt-3 pb-6 lg:hidden">
              <Swiper
                slidesPerView="auto"
                speed={500}
                className="col-span-full w-full md:col-span-full lg:mt-4 xl:mt-5"
                modules={[Mousewheel]}
                mousewheel={{
                  enabled: true,
                }}
                loop
              >
                {featuredPosts.map((post, i) => {
                  return (
                    <SwiperSlide
                      key={post._id + '-' + i}
                      className="pl-5 [&&]:flex [&&]:h-auto [&&]:w-full [&&]:max-w-xs"
                    >
                      <BlogCard {...post} className="w-full" />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          )}
        </div>
      </nav>
    </RemoveScroll>
  )
})

MobileNav.displayName = 'MobileNav'

export default MobileNav
