'use client'
import React, { useState } from 'react'

import { cn } from '@/lib/utils'

type NavDropdownProps = {
  title: string
  className?: string
  children?: React.ReactNode
}

const NavDropdown = ({ title, className, children }: NavDropdownProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleDropdown = () => setIsExpanded(!isExpanded)

  return (
    <li className={cn('group flex flex-col items-start overflow-hidden', className)}>
      <button
        onClick={toggleDropdown}
        className={cn(
          'h4 relative inline-block transition-transform duration-500 before:absolute before:right-full before:top-0 before:block before:h-full before:w-12 before:bg-gradient-to-t before:from-current before:to-current before:bg-[length:36px_1px] before:bg-left before:bg-no-repeat group-hover:translate-x-12',
          {
            'translate-x-12': isExpanded,
          },
        )}
      >
        {title}
      </button>
      <div
        className={cn(
          'grid grid-rows-animate-height-closed transition-all duration-700 ease-in-out group-hover:grid-rows-animate-height-open',
          {
            'grid-rows-animate-height-open': isExpanded,
          },
        )}
      >
        <div className="overflow-hidden">{children}</div>
      </div>
    </li>
  )
}

export default NavDropdown
