import type { FooterPayload } from '@/types'

const Social = ({ links }: { links?: FooterPayload['socialLinks'] }) => {
  if (!links) return null

  return (
    <div className="col-span-full xl:col-start-2">
      <h3 className="h3 mb-3 hidden md:block">Follow us freely</h3>
      <ul className="ml-0 flex items-center justify-center gap-x-4 md:justify-start">
        {Object.entries(links).map(([key, value], index) => {
          if (!value) return null
          let label = ''
          let icon: JSX.Element = <></>

          switch (key) {
            case 'instagram':
              label = 'Instagram'
              icon = (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18 3.375H9C5.8934 3.375 3.375 5.8934 3.375 9V18C3.375 21.1066 5.8934 23.625 9 23.625H18C21.1066 23.625 23.625 21.1066 23.625 18V9C23.625 5.8934 21.1066 3.375 18 3.375ZM21.6562 18C21.6501 20.0167 20.0167 21.6501 18 21.6562H9C6.98327 21.6501 5.34993 20.0167 5.34375 18V9C5.34993 6.98327 6.98327 5.34993 9 5.34375H18C20.0167 5.34993 21.6501 6.98327 21.6562 9V18ZM18.8438 9.28125C19.4651 9.28125 19.9688 8.77757 19.9688 8.15625C19.9688 7.53493 19.4651 7.03125 18.8438 7.03125C18.2224 7.03125 17.7188 7.53493 17.7188 8.15625C17.7188 8.77757 18.2224 9.28125 18.8438 9.28125ZM13.5 8.4375C10.7041 8.4375 8.4375 10.7041 8.4375 13.5C8.4375 16.296 10.7041 18.5625 13.5 18.5625C16.296 18.5625 18.5625 16.296 18.5625 13.5C18.5655 12.1564 18.0331 10.867 17.083 9.91696C16.133 8.96691 14.8436 8.43451 13.5 8.4375ZM10.4062 13.5C10.4062 15.2086 11.7914 16.5938 13.5 16.5938C15.2086 16.5938 16.5938 15.2086 16.5938 13.5C16.5938 11.7914 15.2086 10.4062 13.5 10.4062C11.7914 10.4062 10.4062 11.7914 10.4062 13.5Z"
                    fill="#353839"
                  />
                </svg>
              )
              break
            case 'youtube':
              label = 'Youtube'
              icon = (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                >
                  <path
                    d="M24.2923 8.10338C24.1641 7.62796 23.9137 7.19439 23.5659 6.84582C23.2181 6.49726 22.7851 6.24585 22.31 6.11663C20.5483 5.63288 13.5001 5.62501 13.5001 5.62501C13.5001 5.62501 6.45313 5.61713 4.69025 6.07951C4.21542 6.21467 3.7833 6.46963 3.43539 6.81991C3.08748 7.17019 2.83545 7.60402 2.7035 8.07976C2.23888 9.84151 2.23438 13.4955 2.23438 13.4955C2.23438 13.4955 2.22988 17.1675 2.69113 18.9113C2.94988 19.8754 3.70925 20.637 4.6745 20.8969C6.45425 21.3806 13.4833 21.3885 13.4833 21.3885C13.4833 21.3885 20.5314 21.3964 22.2931 20.9351C22.7684 20.8061 23.2018 20.5553 23.5505 20.2075C23.8991 19.8596 24.1509 19.4268 24.281 18.9518C24.7468 17.1911 24.7501 13.5383 24.7501 13.5383C24.7501 13.5383 24.7726 9.86513 24.2923 8.10338ZM11.2456 16.8806L11.2513 10.1306L17.1091 13.5113L11.2456 16.8806Z"
                    fill="#353839"
                  />
                </svg>
              )
              break
            case 'x':
              label = 'X'
              icon = (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                >
                  <path
                    d="M19.3231 4.77344H22.4282L15.6444 12.398L23.625 22.7734H17.3763L12.482 16.4809L6.88188 22.7734H3.77487L11.0308 14.6181L3.375 4.77344H9.78238L14.2064 10.5251L19.3231 4.77344ZM18.2332 20.9458H19.9539L8.84746 6.50513H7.0011L18.2332 20.9458Z"
                    fill="#353839"
                  />
                </svg>
              )
              break
            case 'tikTok':
              label = 'TikTok'
              icon = (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.5888 6.686C18.5902 6.47238 17.6858 5.94535 17.0077 5.18175C16.3295 4.41816 15.913 3.45791 15.8188 2.441V2H12.3738V15.672C12.3717 16.2773 12.18 16.8667 11.8256 17.3574C11.4712 17.848 10.972 18.2154 10.3981 18.4077C9.82418 18.6 9.20443 18.6077 8.62593 18.4297C8.04743 18.2516 7.53927 17.8968 7.17284 17.415C6.80072 16.9252 6.5943 16.3296 6.5835 15.7145C6.57271 15.0995 6.7581 14.497 7.11281 13.9944C7.46751 13.4918 7.97307 13.1153 8.55619 12.9194C9.13931 12.7235 9.76966 12.7185 10.3558 12.905V9.405C9.04869 9.2243 7.71775 9.45669 6.54919 10.0697C5.38062 10.6826 4.43288 11.6455 3.83852 12.8237C3.24416 14.0018 3.03291 15.3363 3.23432 16.6404C3.43573 17.9445 4.03973 19.1531 4.96184 20.097C5.8412 20.9967 6.96872 21.6141 8.20049 21.8701C9.43226 22.1262 10.7124 22.0094 11.8775 21.5346C13.0426 21.0599 14.0398 20.2487 14.7418 19.2047C15.4438 18.1606 15.8188 16.9311 15.8188 15.673V8.687C17.2111 9.68234 18.8804 10.216 20.5918 10.213V6.79C20.2548 6.79042 19.9186 6.75556 19.5888 6.686Z"
                    fill="#353839"
                  />
                </svg>
              )
              break
            case 'linkedIn':
              label = 'LinkedIn'
              icon = (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.0625 3.64844C4.13052 3.64844 3.375 4.40395 3.375 5.33594V22.2109C3.375 23.1429 4.13052 23.8984 5.0625 23.8984H21.9375C22.8694 23.8984 23.625 23.1429 23.625 22.2109V5.33594C23.625 4.40395 22.8694 3.64844 21.9375 3.64844H5.0625ZM9.58585 8.1515C9.59219 9.22728 8.78694 9.89015 7.83138 9.8854C6.9312 9.88066 6.14652 9.164 6.15126 8.15308C6.15601 7.20228 6.90748 6.43816 7.88359 6.46031C8.87394 6.48246 9.59219 7.20861 9.58585 8.1515ZM13.8147 11.2554H10.9797H10.9781V20.8852H13.9744V20.6606C13.9744 20.2332 13.9741 19.8057 13.9737 19.3781C13.9728 18.2375 13.9718 17.0958 13.9777 15.9556C13.9792 15.6787 13.9918 15.3909 14.0631 15.1266C14.3304 14.1394 15.218 13.5019 16.2083 13.6586C16.8443 13.7581 17.265 14.1268 17.4422 14.7264C17.5515 15.1013 17.6005 15.5047 17.6052 15.8955C17.6181 17.0741 17.6163 18.2526 17.6145 19.4313C17.6138 19.8473 17.6131 20.2636 17.6131 20.6796V20.8837H20.619V20.6527C20.619 20.1442 20.6188 19.6358 20.6184 19.1274C20.6179 17.8567 20.6172 16.5861 20.6206 15.3149C20.6221 14.7406 20.5605 14.1743 20.4197 13.619C20.2093 12.7931 19.7742 12.1097 19.0671 11.6161C18.5655 11.2649 18.015 11.0387 17.3996 11.0134C17.3295 11.0104 17.2589 11.0066 17.1879 11.0028C16.8732 10.9858 16.5534 10.9685 16.2525 11.0292C15.3919 11.2016 14.6358 11.5956 14.0646 12.29C13.9983 12.3697 13.9333 12.4506 13.8365 12.5713L13.8147 12.5986V11.2554ZM6.39185 20.8884H9.37397V11.2617H6.39185V20.8884Z"
                    fill="#353839"
                  />
                </svg>
              )
              break
          }

          return (
            <li key={index}>
              <a href={value} target="_blank" rel="noreferrer" className="">
                <span className="body hover-underline hidden md:block">{label}</span>
                <span className="h-[27px] w-[27px] md:hidden">{icon}</span>
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Social
