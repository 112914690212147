import { cn } from '@/lib/utils'

type HamburgerProps = {
  isOpened: boolean
  setIsOpened: (isOpened: boolean) => void
  className?: string
  closeAllMenus: () => void
}

const Hamburger = ({ isOpened, setIsOpened, className, closeAllMenus }: HamburgerProps) => {
  const toggleHeader = () => {
    if (isOpened) {
      closeAllMenus()
    } else {
      setIsOpened(true)
    }
  }

  return (
    <button
      className={cn('mr-4 flex h-6 w-6 flex-col justify-center gap-y-1 lg:hidden', className)}
      onClick={toggleHeader}
    >
      <span className="sr-only">Toggle Menu</span>
      <span
        className={cn(
          'relative top-0 block h-0.5 w-full bg-current [transition:transform_300ms_ease-in-out,top_300ms_300ms_ease-in-out]',
          {
            'top-1.5 rotate-45 transform [transition:transform_300ms_300ms_ease-in-out,top_300ms_ease-in-out]':
              isOpened,
          },
        )}
      ></span>
      <span
        className={cn('relative block h-0.5 w-full bg-current transition-opacity duration-500', {
          'opacity-0': isOpened,
        })}
      ></span>
      <span
        className={cn(
          'relative top-0 block h-0.5 w-full bg-current [transition:transform_300ms_ease-in-out,top_300ms_300ms_ease-in-out]',
          {
            '-top-1.5 -rotate-45 transform [transition:transform_300ms_300ms_ease-in-out,top_300ms_ease-in-out]':
              isOpened,
          },
        )}
      ></span>
    </button>
  )
}

export default Hamburger
